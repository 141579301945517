import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { AudioPlayerProvider, useAudioPlayer, useAudioPosition } from 'react-use-audio-player';

import type { SharedPlayerProps } from '../../player';
import { PlayerControls } from '../player-controls';
import { RetryButton } from '../retry-button';
import styles from './audio-player.module.scss';

export interface AudioPlayerProps extends SharedPlayerProps {
  className?: string;
}

export function AudioPlayer({ onProgressChange, className, file, currentTime }: AudioPlayerProps) {
  return (
    <AudioPlayerProvider>
      <Player onProgressChange={onProgressChange} className={className} file={file} currentTime={currentTime} />
    </AudioPlayerProvider>
  );
}

function Player({ onProgressChange, className, file, currentTime }: AudioPlayerProps) {
  const classes = cn(styles.audioPlayer, className);
  const { load, togglePlayPause, ready, loading, playing, player } = useAudioPlayer({
    autoplay: false,
    html5: true,
  });
  const { duration, position, percentComplete, seek } = useAudioPosition({
    highRefreshRate: true,
  });

  useEffect(() => {
    if (typeof currentTime?.seconds === 'number') {
      if (!playing) {
        togglePlayPause();
      }
      seek(currentTime.seconds);
    }
  }, [currentTime]);

  const tryCount = useRef(0);

  if (!ready && !loading) {
    return (
      <div className={classes}>
        <RetryButton
          file={file}
          onLoad={(src: string) => {
            // load не вызывается если src не отличается от предыдущего
            load({ src: `${src}?try=${tryCount.current}` });
            tryCount.current += 1;
          }}
        />
      </div>
    );
  }

  return (
    <div className={classes}>
      <PlayerControls
        file={file}
        loading={loading}
        playing={playing}
        onLoad={(src: string) => load({ src })}
        onTogglePlayPause={togglePlayPause}
        duration={duration}
        percentComplete={percentComplete}
        onSeek={seek}
        ready={ready}
        onRate={(rate: number) => player?.rate(rate)}
        position={position}
        onProgressChange={onProgressChange}
      />
    </div>
  );
}

